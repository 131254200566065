exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-based-marketing-js": () => import("./../../../src/pages/accountBasedMarketing.js" /* webpackChunkName: "component---src-pages-account-based-marketing-js" */),
  "component---src-pages-appointment-setting-js": () => import("./../../../src/pages/appointmentSetting.js" /* webpackChunkName: "component---src-pages-appointment-setting-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-content-syndication-js": () => import("./../../../src/pages/contentSyndication.js" /* webpackChunkName: "component---src-pages-content-syndication-js" */),
  "component---src-pages-data-mining-js": () => import("./../../../src/pages/dataMining.js" /* webpackChunkName: "component---src-pages-data-mining-js" */),
  "component---src-pages-email-marketing-js": () => import("./../../../src/pages/emailMarketing.js" /* webpackChunkName: "component---src-pages-email-marketing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lead-generation-js": () => import("./../../../src/pages/leadGeneration.js" /* webpackChunkName: "component---src-pages-lead-generation-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

